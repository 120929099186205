import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, Link } from "react-router-dom";
import AppContext from "./context/AppContext";
import { I18nextProvider } from "react-i18next";
import { AuthInterceptor } from "./AuthInterceptor";
import useInitialState from "./hooks/useInitialState";
import i18n from "./i18n";
import Home from "./pages/Home";
import Finance from "./pages/Finance";
import Packages from "./pages/Packages";
import Network from "./pages/Network";
import Layout from "./Layout/Layout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import LayoutAdmin from "./Layout/LayoutAdmin";
import Profile from "./pages/Profile";
import Auth from "./pages/Auth";
import AdminPackage from "./pages/AdminPackage";
import Recover from "./pages/Recover";
import Withdrawal from "./pages/Withdrawal";
export default function App() {
  const initialState = useInitialState();

  useEffect(() => {
    AuthInterceptor();
  }, []);

  useEffect(() => {
    const obtenerPrecioSolana = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=tron&vs_currencies=usd"
        );
        const data = await response.json();
        const precioUSD = data.tron.usd;
        window.localStorage.setItem("tron", precioUSD.toFixed(3));
      } catch (error) {
        window.localStorage.setItem("tron", "0");
      }
    };
    obtenerPrecioSolana();
    const intervalId = setInterval(obtenerPrecioSolana, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/finance"
                element={
                  <PrivateRoute>
                    <Finance />
                  </PrivateRoute>
                }
              />
              <Route
                path="/withdrawal"
                element={
                  <PrivateRoute>
                    <Withdrawal />
                  </PrivateRoute>
                }
              />
              <Route
                path="/packages"
                element={
                  <PrivateRoute>
                    <Packages />
                  </PrivateRoute>
                }
              />
              <Route
                path="/network"
                element={
                  <PrivateRoute>
                    <Network />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/auth"
                element={
                  <PrivateRoute>
                    <Auth />
                  </PrivateRoute>
                }
              />
              <Route
                path="/packageadmin"
                element={
                  <PrivateRoute>
                    <AdminPackage />
                  </PrivateRoute>
                }
              />
              <Route path="/signin" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/recover-password" element={<Recover />} />
            </Routes>
          </Layout>
        </I18nextProvider>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

function PrivateRoute({ children }) {
  const [state, setState] = useState("loading");
  useEffect(() => {
    (async function () {
      try {
        const accountAddress = window.localStorage.getItem("token");
        //const accountAddress = true;
        setState(accountAddress ? "loggedin" : "redirect");
      } catch {
        setState("redirect");
      }
    })();
  }, []);
  if (state === "loading") {
    return <div>Loading..</div>;
  }
  if (state === "redirect") {
    return <Navigate to="/signin" />;
  }
  return <LayoutAdmin> {children} </LayoutAdmin>;
}
