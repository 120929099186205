import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import BackgroundImage from "../assets/svg/Home.svg"
import GladiverseLogo from "../assets/svg/logo.svg"
import AppContext from "../context/AppContext";
import Support from "../assets/svg/support.svg"
import Instagram from "../assets/svg/instagram.svg"
import Telegram from "../assets/svg/telegram.svg"

export default function LayoutAdmin({ children }) {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [username, setUsername] = useState('');
    const location = useLocation();
    const path = location.pathname;
    const { language, showLanguage, notificacions, showNotifications } = useContext(AppContext);

    useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (user) {
            setUsername(user.name);
        }
    }, []);



    const signout = () => {
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("accountAddress");
        window.location.replace("/");
    }

    const AbrirLanguage = (data) => {
        showLanguage(!data);
    }

    const getPageName = (path) => {
        const pathParts = path.split('/');
        return pathParts.length > 1 ? pathParts[1].charAt(0).toUpperCase() + pathParts[1].slice(1) : t("_SIDEBAR._DASHBOARD");
    }

    return (
        <>
            <section className='lg:h-full bg-cover relative px-2 lg:px-10 ' style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <div className="relative w-full h-auto px-6">
                    <button
                        type="button"
                        className="inline-flex items-center mt-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 z-50"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <span className="sr-only">{t("_SIDEBAR._OPEN")}</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button>
                    <aside
                        id="logo-sidebar"
                        className={`z-50 custom-button-shadow fixed lg:absolute top-0 left-0 z-40 w-64 h-screen rounded-xl transition-transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} sm:translate-x-0 lg:top-4 lg:w-full lg:h-auto  `}
                        aria-label="Sidebar"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute top-0 left-0 text-white cursor-pointer w-10 sm:hidden" onClick={() => setMenuOpen(!menuOpen)} >
                            <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                        </svg>
                        <div className="h-full px-3 py-1 overflow-y-auto lg:overflow-y-hidden bg-main-menu rounded-lg flex flex-col lg:flex-row justify-between">
                            <div>
                                <ul className="space-y-1 lg:space-y-0 font-medium flex flex-col lg:flex-row lg:gap-6">
                                    <li class="flex item-center lg:mr-12">
                                        <div className={`flex items-center rounded-lg text-white group ${path === '/' ? 'text-lime-500' : ''}`}>
                                            <Link to="/"> <img src={GladiverseLogo} className="w-3/5 lg:max-w-28 lg:w-auto" alt={"Cryptotradex"} /></Link>
                                        </div>
                                    </li>

                                    <li class="flex item-center">
                                        <Link to="/" className={`flex items-center p-2 rounded-lg text-white hover:opacity-60group ${path === '/' ? 'text-lime-500' : ''}`}>
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._DASHBOARD")}</span>
                                        </Link>
                                    </li>

                                    <li class="flex item-center">
                                        <Link to="/finance" className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group ${path === '/finance' ? 'text-lime-500' : ''}`}>
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._FINANCE")}</span>
                                        </Link>
                                    </li>
                                    <li class="flex item-center">
                                        <Link to="/packages" className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group ${path === '/packages' ? 'text-lime-500' : ''}`}>
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._PACKAGES")}</span>
                                        </Link>
                                    </li>

                                    <li class="flex item-center">
                                        <Link to="/network" className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group ${path === '/network' ? 'text-lime-500' : ''}`}>
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._NETWORK")}</span>
                                        </Link>
                                    </li>

                                    <li class="flex item-center">
                                        <Link to="/auth" className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group ${path === '/auth' ? 'text-lime-500' : ''}`}>
                                            <span className="ms-3 text-xl">2FA</span>
                                        </Link>
                                    </li>
                                    <li class="flex item-center">
                                        <Link to="/profile" className="lg:hidden flex items-center p-2 rounded-lg  text-white  ">
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._PROFILE")}</span>
                                        </Link>
                                    </li>
                                    <li class="flex item-center">
                                        <div onClick={() => AbrirLanguage(language)} className="lg:hidden flex items-center p-2  text-white rounded-lg  group cursor-pointer">
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._LANGUAGE")}</span>
                                        </div>
                                    </li>
                                    <li class="flex item-center">
                                        <div onClick={() => signout()} className="lg:hidden flex items-center p-2 rounded-lg text-red-300 group cursor-pointer">
                                            <span className="ms-3 text-xl">{t("_SIDEBAR._SIGNOUT")}</span>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="hidden lg:block">
                                <ul className="space-y-2 font2 lg:space-y-0 font-medium flex flex-col lg:flex-row">

                                    <li class="flex item-center">
                                        <div onClick={() => showNotifications(!notificacions)} className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5  transition duration-75 group-hover:text-lime-500 group-hover:font-bold cursor-pointer" >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                                            </svg>
                                        </div>
                                    </li>

                                    <li class="flex item-center">
                                        <Link to="/profile"> <div className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5  transition duration-75 group-hover:text-lime-500 group-hover:font-bold cursor-pointer" >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                            </svg>
                                        </div>
                                        </Link>
                                    </li>
                                    <li class="flex item-center">
                                        <div onClick={() => AbrirLanguage(language)} className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5  transition duration-75 group-hover:text-lime-500 group-hover:font-bold cursor-pointer" >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="flex item-center" onClick={() => signout()}>
                                        <div className={`flex items-center p-2 rounded-lg text-white hover:opacity-60 group `}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5  transition duration-75 group-hover:text-red-500 group-hover:font-bold cursor-pointer" >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="bg-slate-950 p-1 lg:mt-10 mt-3 py-4 rounded-lg">
                            <div className="flex justify-around flex-row w-full  my-2 ">
                                <Link to="https://t.me/+JuhgaGj5PrJhMGUx" target={"_blank"}><img src={Telegram} alt="Telegram" className="opacity-40 hover:opacity-100 cursor-pointer w-8" /></Link>
                                <Link to="https://www.instagram.com/gladiaverse" target={"_blank"}><img src={Instagram} alt="Instagran" className="opacity-40 hover:opacity-100 cursor-pointer w-8" /></Link>
                            </div>
                        </div> */}
                        </div>
                    </aside>
                </div>
                <div className="lg:py-28">
                    {children}
                </div>
            </section >
        </>
    );
}
