export const ar = {
  _LOGIN: {
    _EMAIL: "البريد الإلكتروني",
    _PASSWORD: "كلمة المرور",
    _REMEMBER: "تذكرني",
    _LOGIN: "تسجيل الدخول",
    _SIGNUP: "إنشاء حساب",
    _FORGOTPASSWORD: "نسيت كلمة المرور؟",
    _NOACCOUNT: "ليس لديك حساب؟",
    _ERROR_EMAIL: "يبدو أنك غير مسجل! حاول مرة أخرى.",
    _ERROR_PASSWORD: "كلمة مرور غير صحيحة",
    _SUCCESS: "تم تسجيل الدخول بنجاح",
  },
  _REGISTER: {
    _REGISTER: "تسجيل حساب",
    _NAME: "اسم المستخدم",
    _EMAIL: "بريدك الإلكتروني",
    _PASSWORD: "كلمة مرورك",
    _CONFIRMPASSWORD: "تأكيد كلمة المرور",
    _WHATSAPP: "واتساب",
    _TELEGRAM: "تيليجرام (اختياري)",
    _HAVEACCOUNT: "لدي حساب",
    _ERROR_REFERAL: "رمز الإحالة غير صحيح",
    _ERROR_EMAIL: "هذا البريد الإلكتروني موجود بالفعل",
    _SUCCESS: "تم التسجيل بنجاح",
  },
  _SIDEBAR: {
    _PAGES: "الصفحات",
    _OPEN: "فتح القائمة الجانبية",
    _DASHBOARD: "الرئيسية",
    _NETWORK: "الشبكة",
    _FINANCE: "المحفظة",
    _PACKAGES: "الخطط",
    _SIGNOUT: "تسجيل الخروج",
    _LANGUAGE: "اللغة",
    _PROFILE: "الملف الشخصي",
  },
  _DASHBOARD: {
    _TITLE: "الرصيد",
    _TITLE2: "الإيداع",
    _TITLE3: "إجمالي الأرباح",
    _TITLE4: "أرباح Unilevel",
    _TITLE5: "مكافأة الترقية",
    _TITLE6: "الخطة",
    _TOTAL: "المجموع",
    _TABLE3: "قائمة الحزم",
    _START: "البداية",
    _FINISH: "النهاية",
    _STATUS: "الحالة",
    _STATUS_ACTIVE: "نشط",
    _STATUS_INACTIVE: "غير نشط",
    _CURRENT: "الحالي",
    _UPGRADE: "ترقية",
    _COPYMESSAGE: "تم النسخ بنجاح",
    _NOTIFICATION1: "إيداع جديد",
    _NOTIFICATION2: "أرباح Cryptotradex",
    _NOTIFICATION3: "أرباح Unilevel",
    _NOTIFICATION4: "النطاق غير محدود",
    _NOTIFICATION5: "شراء الحزمة",
    _PREV: "السابق",
    _NEXT: "التالي",
    _TABLE4_A: "العملية",
    _TABLE4_B: "الأرباح",
    _TABLE4_C: "عائد الاستثمار (ROI)",
    _TABLE4_D: "التاريخ",
    _SHARED: "شارك رابط الإحالة الخاص بك",
  },
  _FINANCE: {
    _DEPOSIT: "الإيداع",
    _QR: "قم بمسح QR التالي أو انسخ العنوان",
    _NETWORK: "الشبكة",
    _INSTRUCTIONS: "تعليمات إرسال TRON (TRX)",
    _STEP1: "تأكد من أن الشبكة المختارة هي TRON (TRC20).",
    _STEP2: "أرسل المبلغ من TRON (TRX) إلى عنوان المحفظة التالي.",
    _COPY: "نسخ",
    _DISCLAIMER:
      "إخلاء المسؤولية: نحن غير مسؤولين عن المعاملات التي تتم خارج شبكة TRON (TRC20) أو عن إرسال العملات المشفرة غير TRON (TRX). ستكون أي خسارة للأموال نتيجة لهذه الأخطاء على عاتق المرسل وحده.",
    _TABLE1: "تاريخ الإيداع",
    _TABLE1_A: "الطلب",
    _TABLE1_B: "المبلغ",
    _TABLE1_C: "التاريخ",
    _TABLE1_D: "الحالة",
    _SUCCESS: "تمت إضافة الحزمة بنجاح",
    _COPYMESSAGE: "تم نسخ عنوان المحفظة بنجاح",
  },
  _PACKAGES: {
    _ATTENTION:
      "تنبيه: لديك حساب شركات. بشرائك لحزمة جديدة، سيتم إعادة ضبط الحساب وجميع فوائده.",
    _PACKAGES: "اختر الخطة الأفضل لك",
    _SUBTITLE: "لديك 8 خيارات",
    _BALANCE: "رصيد الإيداع",
    _PACKAGE: "الحزمة",
    _PACKAGE_1: "ستكسب من المستوى",
    _PACKAGE_2: "اكسب حتى 3 مرات",
    _PACKAGE_3: "عائد الاستثمار اليومي",
    _BUTTON1: "ممتلئ",
    _BUTTON2: "شراء",
    _CALCULATE: "حساب الخطة",
    _RECHARGE: "ليس لديك أموال كافية، قم بشحن محفظتك",
    _PACKAGEMESSAGE: "يجب عليك شراء حزمة أعلى",
    _SUCCESS: "تمت إضافة الحزمة بنجاح",
  },
  _NETWORK: {
    _TODAY: "اليوم",
    _AVAILABLE: "متاح",
    _WITHDRAW: "إجمالي المسحوبات",
    _SEARCHLABEL: "البحث عن البريد الإلكتروني",
    _SEARCH: "بحث",
    _NETWORK: "الشبكة",
    _DIRECTS: "الإحالات المباشرة",
    _SUCCESS: "تم البحث بنجاح",
    _ERROR: "ليس جزءاً من شجرتك الثنائية.",
    _LEVEL: "المستوى",
    _USERS: "المستخدمون",
    _AMOUNT: "المبلغ",
    _USERNAME: "اسم المستخدم",
    _EMAIL: "البريد الإلكتروني",
    _WHATSAPP: "واتساب",
    _PACKAGE: "الحزمة",
  },
  _PROFILE: {
    _TITLE: "الملف الشخصي",
    _NAME: "اسمك",
    _EMAIL: "بريدك الإلكتروني",
    _WHATSAPP: "واتساب",
    _CURRENTPASSWORD: "كلمة المرور الحالية",
    _PASSWORD: "كلمة مرور جديدة",
    _CONFIRMPASSWORD: "تأكيد كلمة المرور الجديدة",
    _WALLET: "عنوان السحب",
    _ACTION: "تعديل",
    _TIPS: "نصائح",
    _TIPS_1: "يجب أن يكون الاسم قابلاً للقراءة ولا يزيد عن 20 حرفًا.",
    _TIPS_2:
      "يجب أن تتضمن كلمة المرور أرقامًا وأحرفًا ورموزًا؛ كلما كانت أكثر تعقيدًا كان ذلك أفضل.",
    _TIPS_3:
      "لا يمكن تغيير البريد الإلكتروني. إذا كنت بحاجة إلى تغييره، يرجى التواصل مع الدعم.",
    _TIPS_4:
      "لا يمكن تعديل عنوان السحب إلا باستخدام المصادقة الثنائية، التي يجب تفعيلها مسبقًا.",
    _ERROR_PASSWORD: "خطأ في كلمة المرور السابقة",
    _SUCCESS: "تم تحديث الملف الشخصي بنجاح",
    _MIN: "الحد الأدنى للسحب",
    _UPDATE: "تحديث الملف الشخصي",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "نسيت كلمة المرور؟",
    _EMAIL: "بريدك الإلكتروني",
    _BUTTON: "إرسال البريد الإلكتروني",
    _MESSAGE: "تم إرسال كلمة المرور الخاصة بك إلى بريدك الإلكتروني",
    _ERROR_EMAIL: "هذا البريد الإلكتروني غير مسجل",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "الرتبة الحالية",
    _NEXT: "الرتبة التالية",
    _SILVER: "فضي",
    _GOLD: "ذهبي",
    _RUBY: "ياقوتي",
    _EMERALD: "زمرد",
    _DIAMOND: "ألماسي",
    _DIAMONDB: "ألماسي أزرق",
    _DIAMONDBB: "ألماسي أسود",
    _NONE: "لا شيء",
  },
  _AUTH: {
    _TITLEDEFAULT: "تغيير محفظة السحب",
    _TITLE: "الخطوة 1",
    _TITLE2: "الخطوة 2",
    _TITLE3: "الخطوة 3",
    _STEP1: "قم بتنزيل وتثبيت تطبيق المصادقة على جهازك",
    _STEP2: "امسح الرمز لحفظه",
    _FORMEMAIL: "التحقق من البريد الإلكتروني",
    _FORMHELPEMAIL: "أدخل رمز التحقق المكون من 6 أرقام الذي تم إرساله إلى",
    _FORMHELPAUTH:
      "أدخل رمز المصادقة المكون من 6 أرقام الذي تم إنشاؤه بواسطة التطبيق",
    _FORM1: "رمز المصادقة",
    _FORM2: "عنوان السحب",
    _FORMMIN: "يجب أن يتكون عنوان السحب من 34 حرفًا",
    _FORMCODESEND: "تم إرسال الرمز",
    _VERIFF: "تحقق",
    _ACTION: "تحقق واحفظ",
    _ERROR: "حدث خطأ، حاول مرة أخرى.",
    _SUCCESS: "تم التغيير بنجاح",
    _RETRYOTP: "خطأ في إرسال الرمز، حاول مرة أخرى.",
    _SENDOTP: "تم إرسال الرمز بنجاح",
    _SUCCESSOTP: "تم التحقق من الرمز OTP بنجاح",
    _SUCCESSAUTH2F: "تم التحقق بنجاح من المصادقة الثنائية",
    _ERRORCODE: "رمز غير صحيح، حاول مرة أخرى",
  },
  _COUNTDOWN: {
    _TITLE: "العد التنازلي التالي:",
    _DAYS: "أيام",
    _HOURS: "ساعات",
    _MINUTES: "دقائق",
    _SECONDS: "ثواني",
  },
  _WITHDRAWAL: {
    _TITLE: "معاملات السحب",
  },
};
