export const rus = {
  _LOGIN: {
    _EMAIL: "Электронная почта",
    _PASSWORD: "Пароль",
    _REMEMBER: "Запомнить меня",
    _LOGIN: "ВОЙТИ",
    _SIGNUP: "Зарегистрироваться",
    _FORGOTPASSWORD: "Забыли пароль?",
    _NOACCOUNT: "Нет аккаунта?",
    _ERROR_EMAIL: "Кажется, вы не зарегистрированы! Попробуйте снова.",
    _ERROR_PASSWORD: "Неверный пароль",
    _SUCCESS: "Успешный вход в систему",
  },
  _REGISTER: {
    _REGISTER: "Создать аккаунт",
    _NAME: "Имя пользователя",
    _EMAIL: "Ваш электронный адрес",
    _PASSWORD: "Ваш пароль",
    _CONFIRMPASSWORD: "Подтвердить пароль",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (опционально)",
    _HAVEACCOUNT: "У меня уже есть аккаунт",
    _ERROR_REFERAL: "Неверный реферальный код",
    _ERROR_EMAIL: "Этот адрес электронной почты уже существует",
    _SUCCESS: "Успешная регистрация",
  },
  _SIDEBAR: {
    _PAGES: "Страницы",
    _OPEN: "Открыть боковую панель",
    _DASHBOARD: "Главная",
    _NETWORK: "Сеть",
    _FINANCE: "Кошелёк",
    _PACKAGES: "Планы",
    _SIGNOUT: "Выйти",
    _LANGUAGE: "Язык",
    _PROFILE: "Профиль",
  },
  _DASHBOARD: {
    _TITLE: "Баланс",
    _TITLE2: "Депозит",
    _TITLE3: "Общий заработок",
    _TITLE4: "Unilevel заработок",
    _TITLE5: "Бонус за ранг",
    _TITLE6: "План",
    _TOTAL: "Всего",
    _TABLE3: "Список пакетов",
    _START: "Начало",
    _FINISH: "Конец",
    _STATUS: "Статус",
    _STATUS_ACTIVE: "Активен",
    _STATUS_INACTIVE: "Неактивен",
    _CURRENT: "Текущий",
    _UPGRADE: "Обновить",
    _COPYMESSAGE: "Копирование выполнено успешно",
    _NOTIFICATION1: "Новый депозит",
    _NOTIFICATION2: "Доход от Cryptotradex",
    _NOTIFICATION3: "Доход Unilevel",
    _NOTIFICATION4: "Бесконечный ранг",
    _NOTIFICATION5: "Покупка пакета",
    _PREV: "Предыдущий",
    _NEXT: "Следующий",
    _TABLE4_A: "Операция",
    _TABLE4_B: "Доход",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Дата",
    _SHARED: "Поделитесь своей реферальной ссылкой",
  },
  _FINANCE: {
    _DEPOSIT: "Депозит",
    _QR: "Сканируйте QR-код или скопируйте адрес",
    _NETWORK: "Сеть",
    _INSTRUCTIONS: "Инструкции по отправке TRON (TRX)",
    _STEP1: "Убедитесь, что выбранная сеть — это TRON (TRC20).",
    _STEP2: "Отправьте сумму TRON (TRX) на следующий адрес кошелька.",
    _COPY: "Копировать",
    _DISCLAIMER:
      "Отказ от ответственности: Мы не несем ответственности за транзакции, совершенные вне сети TRON (TRC20), или за отправку криптовалюты, отличной от TRON (TRX). Любая потеря средств в результате этих ошибок будет на ответственности отправителя.",
    _TABLE1: "История депозитов",
    _TABLE1_A: "Заказ",
    _TABLE1_B: "Сумма",
    _TABLE1_C: "Дата",
    _TABLE1_D: "Статус",
    _SUCCESS: "Пакет успешно добавлен",
    _COPYMESSAGE: "Адрес кошелька успешно скопирован",
  },
  _PACKAGES: {
    _ATTENTION:
      "Внимание: У вас корпоративный аккаунт. При покупке пакета вы сбросите аккаунт и все его преимущества.",
    _PACKAGES: "Выберите лучший план для вас",
    _SUBTITLE: "У вас есть 8 вариантов",
    _BALANCE: "Баланс депозита",
    _PACKAGE: "Пакет",
    _PACKAGE_1: "Вы начнете зарабатывать с уровня",
    _PACKAGE_2: "Заработайте до 3Х",
    _PACKAGE_3: "Ежедневный ROI",
    _BUTTON1: "Заполнен",
    _BUTTON2: "Купить",
    _CALCULATE: "Рассчитать план",
    _RECHARGE: "У вас недостаточно средств, пополните свой кошелёк",
    _PACKAGEMESSAGE: "Вам нужно купить более высокий пакет",
    _SUCCESS: "Пакет успешно добавлен",
  },
  _NETWORK: {
    _TODAY: "Сегодня",
    _AVAILABLE: "Доступно",
    _WITHDRAW: "Всего выведено",
    _SEARCHLABEL: "Поиск по email",
    _SEARCH: "Поиск",
    _NETWORK: "Сеть",
    _DIRECTS: "Прямые",
    _SUCCESS: "Поиск успешно выполнен",
    _ERROR: "Не является частью вашего бинарного дерева.",
    _LEVEL: "Уровень",
    _USERS: "Пользователи",
    _AMOUNT: "Сумма",
    _USERNAME: "Имя пользователя",
    _EMAIL: "Электронная почта",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Пакет",
  },
  _PROFILE: {
    _TITLE: "Профиль",
    _NAME: "Ваше имя",
    _EMAIL: "Ваш email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Текущий пароль",
    _PASSWORD: "Новый пароль",
    _CONFIRMPASSWORD: "Подтвердите новый пароль",
    _WALLET: "Адрес для вывода",
    _ACTION: "Редактировать",
    _TIPS: "Советы",
    _TIPS_1: "Имя должно быть читаемым и не превышать 20 символов.",
    _TIPS_2:
      "Пароль должен включать числа, буквы и символы; чем сложнее, тем лучше.",
    _TIPS_3:
      "Электронная почта не может быть изменена. Если вам нужно сделать изменение, пожалуйста, напишите в службу поддержки.",
    _TIPS_4:
      "Адрес для вывода может быть изменен только с помощью двухфакторной аутентификации, которая должна быть включена заранее.",
    _ERROR_PASSWORD: "Ошибка предыдущего пароля",
    _SUCCESS: "Профиль успешно обновлен",
    _MIN: "Минимальная сумма для вывода",
    _UPDATE: "Обновить профиль",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Забыли пароль?",
    _EMAIL: "Ваш email",
    _BUTTON: "Отправить email",
    _MESSAGE: "Ваш пароль был отправлен на вашу электронную почту",
    _ERROR_EMAIL: "Этот email не зарегистрирован",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Текущий ранг",
    _NEXT: "Следующий ранг",
    _SILVER: "Серебро",
    _GOLD: "Золото",
    _RUBY: "Рубин",
    _EMERALD: "Изумруд",
    _DIAMOND: "Алмаз",
    _DIAMONDB: "Синий алмаз",
    _DIAMONDBB: "Черный алмаз",
    _NONE: "Нет",
  },
  _AUTH: {
    _TITLEDEFAULT: "Изменить адрес для вывода",
    _TITLE: "Шаг 1",
    _TITLE2: "Шаг 2",
    _TITLE3: "Шаг 3",
    _STEP1: "Загрузите и установите аутентификатор на ваше устройство",
    _STEP2: "Отсканируйте код для сохранения",
    _FORMEMAIL: "Подтверждение по электронной почте",
    _FORMHELPEMAIL: "Введите 6-значный код подтверждения, отправленный на",
    _FORMHELPAUTH:
      "Введите 6-значный код, сгенерированный приложением аутентификатора",
    _FORM1: "Пин аутентификатора",
    _FORM2: "Адрес для вывода",
    _FORMMIN: "Адрес для вывода должен быть длиной 34 символа",
    _FORMCODESEND: "Код отправлен",
    _VERIFF: "Проверить",
    _ACTION: "Проверить и сохранить",
    _ERROR: "Ошибка, попробуйте снова.",
    _SUCCESS: "Изменение успешно",
    _RETRYOTP: "Ошибка при отправке кода, попробуйте снова.",
    _SENDOTP: "Код успешно отправлен",
    _SUCCESSOTP: "OTP успешно подтвержден",
    _SUCCESSAUTH2F: "Двухфакторная аутентификация успешно подтверждена",
    _ERRORCODE: "Неверный код, попробуйте снова",
  },
  _COUNTDOWN: {
    _TITLE: "Следующий отсечка:",
    _DAYS: "Дни",
    _HOURS: "Часы",
    _MINUTES: "Минуты",
    _SECONDS: "Секунды",
  },
  _WITHDRAWAL: {
    _TITLE: "Транзакции вывода",
  },
};
