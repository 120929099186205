import { useState, useEffect } from "react";
import initialState from "../initialState";
const { REACT_APP_PROJECT_ID } = process.env;

const useInitialState = (key, initialValue) => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(initialState.loading);
  const [language, setLanguage] = useState(initialState.language);
  const [notificacions, setNotifications] = useState(
    initialState.notificacions
  );

  const updateLoading = (newLoading) => {
    setLoading(newLoading);
  };

  const showLanguage = (newLoading) => {
    setLanguage(newLoading);
  };

  const showNotifications = (newLoading) => {
    setNotifications(newLoading);
  };

  const setBalance = (payload) => {
    setState((prevState) => {
      return {
        ...prevState,
        balance: payload,
      };
    });
  };

  return {
    setBalance,
    updateLoading,
    showLanguage,
    showNotifications,
    language,
    loading,
    notificacions,
    state,
  };
};

export default useInitialState;
