import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import AppContext from "../context/AppContext";
import CustomButton from '../components/CustomButton';
import toast from "react-hot-toast";
import { useForm } from 'react-hook-form';
import Apple from "../assets/svg/apple.svg"
import Android from "../assets/svg/google.svg"
import Logo from "../assets/svg/logo.svg"

export default function Auth() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [tokenSaved, setTokenSaved] = React.useState(false);
  const [auth2f, setauth2f] = React.useState([]);
  const { loading, updateLoading } = useContext(AppContext);
  const [emailUser, setEmailUser] = useState("");
  const [isVerified, setIsVerified] = useState(0);
  const [isVerifiedOTP, setIsVerifiedOTP] = useState(0);

  useEffect(() => {
    getWalletWithdrawal();
  }, []);





  const sendOTP = () => {
    fetch(`${process.env.REACT_APP_BACKEND}/sendotp`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error(t("_AUTH._ERROR"));
        } else {
          toast.success(t("_AUTH._SENDOTP"));
          setIsVerifiedOTP(1);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const veriffOTP = () => {
    const formData = new FormData();
    formData.append("pin", watch('otp'));
    fetch(`${process.env.REACT_APP_BACKEND}/otp-veriff`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error(t("_AUTH._ERRORCODE"));
        } else {
          toast.success(t("_AUTH._SUCCESSOTP"));
          setIsVerifiedOTP(2);
        }

      })
      .catch((error) => {
        console.log(error);
        toast.error(t("_AUTH._ERROR"));
      });
  };



  const veriffAuthf = () => {
    const formData = new FormData();
    formData.append("pin", watch('authf'));
    fetch(`${process.env.REACT_APP_BACKEND}/authf-veriff`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error(t("_AUTH._ERRORCODE"));
        } else {
          toast.success(t("_AUTH._SUCCESSAUTH2F"));
          setIsVerified(2);
        }

      })
      .catch((error) => {
        console.log(error);
        toast.error(t("_AUTH._ERROR"));
      });
  };

  const getWalletWithdrawal = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/authf`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          updateLoading(false);
        } else {
          setEmailUser(response?.data?.email);
          if (response?.auth2f) {
            setauth2f(response?.auth2f)
          }
          if (response?.data?.withdrawal_address) {
            setTokenSaved(true);
          }
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const saveWithdrawal = (data) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("withdrawal_address", data?.withdrawal_address);
    fetch(`${process.env.REACT_APP_BACKEND}/changewallet`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (!response.status) {
          toast.error(t("_AUTH._ERROR"));
          updateLoading(false);
        } else {
          toast.success(t("_AUTH._SUCCESS"));
          navigate("/profile");
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const copyWallet = (text) => {
    const lastSegment = text.split(' ').pop(); // Extrae el último segmento del string
    navigator.clipboard.writeText(lastSegment);
    toast.success(t("_FINANCE._COPYMESSAGE"));
  };
  return (
    <div className="container mx-auto">
      {!tokenSaved ? (
        <>
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 my-4 ">
            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg bg-zinc-800 text-white w-full border-lime-600 hover:border-lime-300 border-2">
              <h2 className="text-2xl font-bold mb-4">{t("_AUTH._TITLE")}</h2>
              <p className="mb-4">{t("_AUTH._STEP1")}</p>
              <div className="flex flex-row justify-center items-center gap-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  className="text-white rounded w-60"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Android} alt="Google Play Store" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  className="text-white rounded w-60"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Apple} alt="Apple Store" />
                </a>
              </div>
            </div>
            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg  bg-zinc-800  text-white w-full border-lime-600 hover:border-lime-300 border-2">
              <h2 className="text-2xl font-bold mb-4">{t("_AUTH._TITLE2")}</h2>
              <p className="mb-4">{t("_AUTH._STEP2")}</p>
              <img src={auth2f?.src ? auth2f?.src : Logo} alt="Logo" className="mx-auto w-1/2 my-2 rounded-lg p-2 bg-white" />
              <p className="text-white"> {auth2f?.title}</p>
              <div className="text-black">
                <CustomButton text={t("_FINANCE._COPY")} onClick={() => copyWallet(auth2f?.title)} fontSize={"text-2xl"} />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 my-4">
            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg  bg-zinc-800  text-white w-full border-lime-600 hover:border-lime-300 border-2">
              <h2 className="text-2xl font-bold mb-4">{t("_AUTH._TITLE3")}</h2>
              <div className="my-6">
                <label htmlFor="pin" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORMEMAIL")}</label>
                <div className="flex items-center text-white ">
                  <input
                    type="otp"
                    name="otp"
                    id="otp"
                    className={`bg-gray-900 custom-button-shadow text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4  ${isVerifiedOTP === 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    placeholder=""
                    {...register('otp', { required: true })}
                    disabled={isVerifiedOTP === 2}
                  />
                  {isVerifiedOTP === 0 && (
                    <button
                      onClick={sendOTP}
                      className="w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center"
                    >
                      {t("_AUTH._FORMCODESEND")}
                    </button>
                  )}
                  {isVerifiedOTP === 1 && (
                    <button
                      onClick={() => veriffOTP()}
                      type="button"
                      className={`w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center ${(!watch('otp') || watch('otp').length < 6) ? 'opacity-50' : ''}`}
                      disabled={!watch('otp') || watch('otp').length < 6}
                    >
                      {t("_AUTH._VERIFF")}
                    </button>
                  )}
                  {isVerifiedOTP === 2 && (
                    <button
                      className="w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500">
                        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    // Símbolo de check
                  )}
                </div>
                {isVerifiedOTP === 1 && (<p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 dark:text-gray-400"> {t("_AUTH._FORMHELPEMAIL")} <span class="font-medium text-blue-600 hover:underline dark:text-blue-500">{emailUser}</span></p>)}
                {errors.otp && <span className="text-red-500 text-sm">OTP  is required</span>}
              </div>
              <div className="my-6">
                <label htmlFor="pin" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORM1")}</label>
                <div className="flex items-center  text-white ">
                  <input type="pin" name="pin" id="pin" className="bg-gray-900 custom-button-shadow text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4" placeholder=""  {...register('authf', { required: true })} />
                  {isVerified ? (
                    <button
                      className="w-40 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500">
                        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    // Símbolo de check
                  ) : (
                    <button
                      type="button"
                      onClick={veriffAuthf}
                      className={`w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center ${(!watch('authf') || watch('authf').length < 6) ? 'opacity-50' : ''}`}
                      disabled={!watch('authf') || watch('authf').length < 6}
                    >
                      {t("_AUTH._VERIFF")}
                    </button>
                  )}
                </div>
                <p id="helper-text-explanation" class="mt-2 text-sm text-slate-500 dark:text-gray-400">{t("_AUTH._FORMHELPAUTH")}</p>
                {errors.pin && <span className="text-red-500 text-sm">Pin is required</span>}
              </div>
              <form onSubmit={handleSubmit(saveWithdrawal)} className="space-y-4">
                <div>
                  <label htmlFor="withdrawal_address" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORM2")}</label>
                  <input
                    type="text"
                    name="withdrawal_address"
                    id="withdrawal_address"
                    className="bg-gray-900 text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4 "
                    placeholder=""
                    {...register('withdrawal_address', {
                      required: 'Withdrawal address is required',
                      validate: value => value.length === 34 || 'Withdrawal address must be 34 characters long'
                    })}
                  />
                  {errors.withdrawal_address && <span className="text-red-500 text-sm">{errors.withdrawal_address.message}</span>}
                </div>
                <button
                  type="submit"
                  className={`custom-button-shadow text-md w-full border-2 p-1 cursor-pointer rounded-lg ${isVerifiedOTP !== 2 || isVerified !== 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isVerifiedOTP !== 2 || isVerified !== 2 || !isVerifiedOTP || !isVerified}
                >
                  {t("_AUTH._ACTION")}
                </button>

              </form>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 my-4">
          <div className="flex-1 bg-white p-6 rounded-lg shadow-lg  bg-zinc-800  text-white w-full">
            <h2 className="text-2xl font-bold mb-4">{t("_AUTH._TITLE3")}</h2>
            <div className="my-6 w-full">
              <label htmlFor="pin" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORMEMAIL")}</label>
              <div className="flex items-center  text-white ">
                <input
                  type="otp"
                  name="otp"
                  id="otp"
                  className={`bg-gray-900 custom-button-shadow text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4 ${isVerifiedOTP === 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  placeholder=""
                  {...register('otp', { required: true })}
                  disabled={isVerifiedOTP === 2}
                />
                {isVerifiedOTP === 0 && (
                  <button
                    onClick={sendOTP}
                    className="w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center"
                  >
                    {t("_AUTH._FORMCODESEND")}
                  </button>
                )}
                {isVerifiedOTP === 1 && (
                  <button
                    onClick={() => veriffOTP()}
                    type="button"
                    className={`w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center ${(!watch('otp') || watch('otp').length < 6) ? 'opacity-50' : ''}`}
                    disabled={!watch('otp') || watch('otp').length < 6}
                  >
                    {t("_AUTH._VERIFF")}
                  </button>
                )}
                {isVerifiedOTP === 2 && (
                  <button
                    className="w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500">
                      <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                    </svg>
                  </button>
                  // Símbolo de check
                )}
              </div>
              {isVerifiedOTP === 1 && (<p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 dark:text-gray-400"> {t("_AUTH._FORMHELPEMAIL")} <span class="font-medium text-blue-600 hover:underline dark:text-blue-500">{emailUser}</span></p>)}
              {errors.otp && <span className="text-red-500 text-sm">OTP  is required</span>}
            </div>
            <div className="my-6">
              <label htmlFor="pin" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORM1")}</label>
              <div className="flex items-center text-white ">
                <input type="pin" name="pin" id="pin" className="bg-gray-900 custom-button-shadow text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4" placeholder=""  {...register('authf', { required: true })} />
                {isVerified ? (
                  <button
                    className="w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500">
                      <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                    </svg>
                  </button>
                  // Símbolo de check
                ) : (
                  <button
                    type="button"
                    onClick={veriffAuthf}
                    className={`w-60 ml-2 px-4 py-2 text-md  border-lime-600 hover:border-lime-300 border-2 p-1 cursor-pointer rounded-lg flex justify-center ${(!watch('authf') || watch('authf').length < 6) ? 'opacity-50' : ''}`}
                    disabled={!watch('authf') || watch('authf').length < 6}
                  >
                    {t("_AUTH._VERIFF")}
                  </button>
                )}
              </div>
              <p id="helper-text-explanation" class="mt-2 text-sm text-slate-500 dark:text-gray-400">{t("_AUTH._FORMHELPAUTH")}</p>
              {errors.pin && <span className="text-red-500 text-sm">Pin is required</span>}
            </div>
            <form onSubmit={handleSubmit(saveWithdrawal)} className="space-y-4">
              <div>
                <label htmlFor="withdrawal_address" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORM2")}</label>
                <input
                  type="text"
                  name="withdrawal_address"
                  id="withdrawal_address"
                  className="bg-gray-900 text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4 "
                  placeholder=""
                  {...register('withdrawal_address', {
                    required: 'Withdrawal address is required',
                    validate: value => value.length === 34 || 'Withdrawal address must be 34 characters long'
                  })}
                />
                {errors.withdrawal_address && <span className="text-red-500 text-sm">{errors.withdrawal_address.message}</span>}
              </div>
              <button
                type="submit"
                className={`custom-button-shadow text-md w-full border-2 p-1 cursor-pointer rounded-lg ${isVerifiedOTP !== 2 || isVerified !== 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isVerifiedOTP !== 2 || isVerified !== 2 || !isVerifiedOTP || !isVerified}
              >
                {t("_AUTH._ACTION")}
              </button>
            </form>
          </div>
        </div>
      )
      }
    </div >
  );
}
