import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";
import PackageEmpty from "../assets/svg/empty.svg"
import Package25 from "../assets/svg/25.svg"
import Package100 from "../assets/svg/100.svg"
import Package250 from "../assets/svg/250.svg"
import Package500 from "../assets/svg/500.svg"
import Package1000 from "../assets/svg/1000.svg"
import Package2500 from "../assets/svg/2500.svg"
import Package5000 from "../assets/svg/5000.svg"
import Package10000 from "../assets/svg/10000.svg"
import Package20000 from "../assets/svg/20000.svg"
import Package50000 from "../assets/svg/50000.svg"

export default function Network() {
  const { t } = useTranslation();
  const [tab, setTab] = useState("network");
  const [searchEmail, setSearchEmail] = useState("");
  const [networks, setNetworks] = useState([]);
  const [dateFilter, setDateFilter] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false); // Controla la visibilidad del selector de fecha
  const [directs, setDirects] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [userData, setUserData] = useState([]);
  const [prevUser, setPrevUser] = useState();
  const { loading, updateLoading } = useContext(AppContext);

  useEffect(() => {
    getNetworks();
    getDirects();
    myUser();
  }, []);

  const getNetworks = (date) => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users-networks?date=${date}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {

        // Valores por defecto de los niveles
        const levelsDefault = [
          { level: 1, percentage: '15%', status: 0 },
          { level: 2, percentage: '5%', status: 0 },
          { level: 3, percentage: '5%', status: 0 },
          { level: 4, percentage: '4%', status: 0 },
          { level: 5, percentage: '3%', status: 0 },
          { level: 6, percentage: '2%', status: 0 },
          { level: 7, percentage: '3%', status: 0 },
          { level: 8, percentage: '3%', status: 0 },
          { level: 9, percentage: '3%', status: 0 },
          { level: 10, percentage: '5%', status: 0 },
        ];

        // Datos del response
        const networkData = response?.data || [];

        // Combina los niveles predeterminados con los del response
        const combinedLevels = levelsDefault.map((defaultLevel) => {
          const matchedLevel = networkData.find((item) => parseInt(item.level) === parseInt(defaultLevel.level));
          return matchedLevel ? { ...defaultLevel, ...matchedLevel } : defaultLevel;
        });

        // Actualiza el estado con los niveles combinados
        setNetworks(combinedLevels);
        setDateFilter(response?.date);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };



  const myUser = () => {
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data;
        setUserData(data);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const getUsersDirects = (id) => {
    if (!id) {
      return;
    }

    const encodedId = btoa(id.toString());
    updateLoading(true);
    const formData = new FormData();
    formData.append("identify", encodedId);
    fetch(`${process.env.REACT_APP_BACKEND}/users-directs`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          toast.error(t("_NETWORK._ERROR"));
        } else {
          toast.success(t("_NETWORK._SUCCESS"));
          setDirects(response?.data)
          setTotal(response?.total)
          setPrevUser(response?.id);
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const getDirects = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/directs?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setDirects(response?.data)
        setTotal(response?.total)
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  useEffect(() => {
    getDirects();
  }, [page, limit]);



  const changeDate = (days) => {
    getNetworks(days);
    setShowDatePicker(false);
  };

  const changeDateByDays = (date, days) => {
    const result = new Date(date);
    result.setUTCDate(result.getUTCDate() + days);
    const year = result.getUTCFullYear();
    const month = String(result.getUTCMonth() + 1).padStart(2, '0');
    const day = String(result.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const switchPackage = (position) => {
    switch (position) {
      case "0":
        return PackageEmpty
      case "1":
        return Package25
      case "2":
        return Package100
      case "3":
        return Package250
      case "4":
        return Package500
      case "5":
        return Package1000
      case "6":
        return Package2500
      case "7":
        return Package5000
      case "8":
        return Package10000
      case "9":
        return Package20000
      case "10":
        return Package50000
      default:
        return PackageEmpty; // En caso de que la posición esté fuera del rango esperado
    }
  };


  const formatNumber = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split('.');

    if (decimalPart && decimalPart.length > 3) {
      return `${integerPart}.${decimalPart.slice(0, 3)}`;
    }

    return valueString;
  };



  return (
    <div className="container mx-auto ">
      <ul className="flex flex-wrap justify-center items-center text-sm font-medium text-center text-gray-500 p-1 border-2 rounded-lg w-fit mx-auto my-4">
        <li className="me-2">
          <div
            className={`cursor-pointer inline-block px-4 py-3 w-full rounded-lg ${tab === 'network' ? 'text-black bg-lime-300' : ''}`}
            onClick={() => setTab('network')}
          >
            {t("_NETWORK._NETWORK")}
          </div>
        </li>
        <li className="me-2">
          <div
            className={`cursor-pointer inline-block px-4 py-3 w-full rounded-lg ${tab === 'directs' ? 'text-black bg-lime-300' : ''}`}
            onClick={() => setTab('directs')}
          >
            {t("_NETWORK._DIRECTS")}
          </div>
        </li>
      </ul>
      {
        tab === "network" && (
          <>
            <div className="flex flex-row justify-between items-center">
              <div>
                <div
                  className={`cursor-pointer inline-block px-4 py-2 rounded-lg border-2 text-white`}
                  onClick={() => getNetworks()}
                >
                  {t("_NETWORK._TODAY")}
                </div>
              </div>

              <div className="flex items-center justify-center px-3 h-12 rounded-lg text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 cursor-pointer mx-2"
                  onClick={() => changeDate(changeDateByDays(dateFilter, -1))}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
                {dateFilter}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 cursor-pointer mx-2"
                  onClick={() => changeDate(changeDateByDays(dateFilter, 1))}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </div>

              <div>
                {!showDatePicker ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="px-3 h-12 rounded-lg text-white cursor-pointer"
                    onClick={() => setShowDatePicker(true)} // Abrir el selector de fecha
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>
                ) : (
                  <input type="date" className="border-2 rounded-lg bg-zinc-800 text-white px-4 py-2" onInput={(e) => {
                    if (e.target.value) {
                      changeDate(e.target.value); // Ejecutar solo cuando hay una fecha seleccionada
                    }
                  }} />
                )}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full">
              <div className="relative overflow-x-auto w-full">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                  <thead className="text-md text-white border-b-2">
                    <tr>
                      <th className="px-6 py-4 ">
                        {t("_NETWORK._LEVEL")}

                      </th>
                      <th className="px-6 py-4">
                        {t("_NETWORK._USERS")}
                      </th>
                      <th className="px-6 py-4 ">
                        %
                      </th>
                      <th className="px-6 py-4 ">
                        {t("_NETWORK._AMOUNT")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {networks.map((item, index) => (
                      <tr className={`border-b-2 ${parseInt(userData?.package_binary) >= item?.level ? "" : "bg-gray-500 opacity-40"}`} key={index}>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                          {item?.level}
                        </th>
                        <td className="px-6 py-4 text-white">
                          {item?.total_users ? item?.total_users : 0}
                        </td>
                        <td className="px-6 py-4 text-white">
                          {item?.percentage}
                        </td>
                        <td className="px-6 py-4 text-white">
                          {formatNumber(item?.total_amount || 0)} USD {parseInt(userData?.package_binary) >= item?.level ? "" : "Lost"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )
      }
      {
        tab === "directs" && (
          <>{
            /*}
            {prevUser && (
              <div className="flex flex-row justify-between items-center">
                <div>
                  <div
                    className={`cursor-pointer inline-block px-4 py-2 rounded-lg border-2 text-white`}
                    onClick={() => getUsersDirects(prevUser)}
                  >
                    {t("_SIDEBAR._DASHBOARD")}
                  </div>
                </div>
              </div>
            )}
          */}
            <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full">
              <div className="relative overflow-x-auto w-full">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                  <thead className="text-md text-white border-b-2">
                    <tr>
                      <th className="px-6 py-4 ">
                        {t("_NETWORK._USERNAME")}
                      </th>
                      <th className="px-6 py-4">
                        {t("_NETWORK._EMAIL")}
                      </th>
                      <th className="px-6 py-4 ">
                        {t("_NETWORK._WHATSAPP")}
                      </th>
                      <th className="px-6 py-4 ">
                        {t("_NETWORK._PACKAGE")}
                      </th>
                      <th className="px-6 py-4 ">
                        GV
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {directs.map((item, index) => (
                      <tr className="border-b-2" key={index}>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white cursor-pointer hover:text-lime-300" onClick={() => getUsersDirects(item?.id)}>
                          {item?.name}
                        </th>
                        <td className="px-6 py-4 text-white">
                          {item?.email}
                        </td>
                        <td className="px-6 py-4 text-white">
                          {item?.whatsapps}
                        </td>
                        <td className="px-6 py-4 text-white">
                          <img src={switchPackage(item?.package)} alt={item?.package} className="w-12" />
                        </td>
                        <td className="px-6 py-4 text-white">
                          {formatNumber(item?.range_volumen || 0)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <nav className="flex items-center justify-center flex-column flex-wrap md:flex-row pt-4" aria-label="Table navigation">
                  <ul className="flex justify-center items-center h-8">
                    {page > 1 && (
                      <li>
                        <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.max(prev - 1, 1))}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                          </svg>
                          {t("_DASHBOARD._PREV")}
                        </button>
                      </li>
                    )}
                    <li>
                      <button className={`flex items-center justify-center px-3 h-12 text-white mx-6`}>
                        {page} / {Math.ceil(total / limit)}
                      </button>
                    </li>
                    {page < Math.ceil(total / limit) && (
                      <li>
                        <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(total / limit)))}>
                          {t("_DASHBOARD._NEXT")}
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                          </svg>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </>
        )
      }
    </div >
  );
}
