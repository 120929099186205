import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';
import DogRegister from "../assets/svg/DogRegister.svg"
import BackgroundRegister from "../assets/svg/Register.svg"
import GladiverseLogo from "../assets/svg/logo.svg"
import AppContext from "../context/AppContext";

export default function Recover() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const { loading, updateLoading } = useContext(AppContext);
    const [steps, setSteps] = useState(1)
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [isVerifiedOTP, setIsVerifiedOTP] = useState(0);


    const sendOTP = () => {
        const formData = new FormData();
        formData.append("email", watch('email'));
        formData.append("type", "recovery");
        fetch(`${process.env.REACT_APP_BACKEND}/recovery`, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((response) => {
                if (!response.status) {
                    toast.error(t("_AUTH._ERROR"));
                } else {
                    toast.success(t("_AUTH._SENDOTP"));
                    setIsVerifiedOTP(1);
                    setSteps(2)
                    setEmail(watch('email'));
                }
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    };


    const savePassword = (data) => {
        updateLoading(true);
        const formData = new FormData();
        formData.append("token", data?.token);
        formData.append("password", data?.password);
        formData.append("email", email);
        fetch(`${process.env.REACT_APP_BACKEND}/change`, {
            method: "POST",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                if (!response.status) {
                    toast.error(t("_AUTH._ERROR"));
                    updateLoading(false);
                } else {
                    toast.success(t("_AUTH._SUCCESS"));
                    navigate("/");
                    updateLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    };


    return (
        <div className="flex mx-auto md:h-screen pt:mt-0 bg-cover bg-no-repeat bg-contain" style={{ backgroundImage: `url(${BackgroundRegister})` }}>
            <div className='lg:w-3/5 h-full'>
            </div>
            <div className="flex flex-col justify-center items-center w-full lg:w-9/12 p-8 h-screen lg:h-auto lg:p-6 rounded-lg shadow z-50 bg-cover bg-no-repeat bg-contain  bg-center" style={{ backgroundImage: `url(${DogRegister})` }}>
                <img src={GladiverseLogo} className="w-3/5 mb-4 mx-auto lg:hidden" alt={"Cryptotradex"} />
                <h2 className="text-2xl text-white">
                    {t("_RECOVERPASSWORD._RECOVERPASSWORD")}
                </h2>
                {steps === 1 && (
                    <div className='w-3/4 lg:w-1/2 space-y-4 mt-4'>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_RECOVERPASSWORD._EMAIL")}</label>
                            <input type="email" name="email" id="email" className="bg-gray-900 custom-button-shadow text-white sm:text-sm rounded block w-full custom-button-shadow py-2 pl-4" placeholder="name@company.com" {...register('email', { required: 'Email is required' })} />
                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                        </div>
                        <CustomButton text={t("_RECOVERPASSWORD._BUTTON")} disabled={!watch("email") || loading} fontSize={"text-1xl"} onClick={() => sendOTP()} />
                        <div className="text-sm font-medium text-gray-400 text-center">
                            <Link to="/signin" className="hover:underline text-primary-500"> <span className='text-white'>{t("_REGISTER._HAVEACCOUNT")}</span></Link>
                        </div>
                    </div>
                )}
                {steps === 2 && (
                    <form className='w-3/4 lg:w-1/2 space-y-4 mt-4' onSubmit={handleSubmit(savePassword)} >
                        <div>
                            <label htmlFor="token" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_AUTH._FORM1")}</label>
                            <input type="token" name="token" id="token" className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4" placeholder="" {...register('token', { required: 'Token is required' })} />
                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.token.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_REGISTER._PASSWORD")}</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    id="password"
                                    placeholder=""
                                    className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
                                    {...register('password', {
                                        required: 'Password is required',
                                        minLength: { value: 6, message: 'Password must have at least 6 characters' },
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/,
                                            message: 'The password must contain at least one letter and one number min 6 characters',
                                        },
                                    })}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                    {showPassword ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                                            <path stroke-linecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path stroke-linecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                                            <path stroke-linecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>
                                    )}
                                </div>
                            </div>
                            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 text-white">{t("_REGISTER._CONFIRMPASSWORD")}</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder=""
                                className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
                                {...register('confirmPassword', {
                                    validate: value => value === watch('password') || 'Passwords do not match'
                                })}
                            />
                            {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword.message}</p>}
                        </div>

                        <CustomButton text={t("_RECOVERPASSWORD._BUTTON")} disabled={!watch("token") || !watch("password") || !watch("confirmPassword") || loading} fontSize={"text-1xl"} isSubmit={true} />

                    </form>
                )}
            </div>
        </div>
    );
}