import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';
import BackgroundImage from "../assets/svg/login.svg"
import BgLogin from "../assets/svg/BgLogin.svg"
import CryptotradexLogo from "../assets/svg/logo.svg"
import AppContext from "../context/AppContext";

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, updateLoading } = useContext(AppContext);

    const onSubmit = data => {
        updateLoading(true);
        const formData = new FormData();
        formData.append("email", data?.email);
        formData.append("password", data?.password);
        fetch(`${process.env.REACT_APP_BACKEND}/login`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === "OK") {
                    localStorage.setItem("token", response?.token);
                    localStorage.setItem("address", response?.user?.address);
                    localStorage.setItem("id", response?.user?.id);
                    localStorage.setItem("user", JSON.stringify(response?.user));
                    toast.success(t("_LOGIN._SUCCESS"));
                    navigate("/");
                } else {
                    if (response?.msg === "Incorrect password") {
                        toast.error(t("_LOGIN._ERROR_PASSWORD"));
                    } else {
                        toast.error(t("_LOGIN._ERROR_EMAIL"));
                    }
                    updateLoading(false);
                }
            })
            .catch((error) => {
                updateLoading(false);
                console.error('Error:', error);
            });
    };


    return (
        <div className="flex mx-auto md:h-screen pt:mt-0 justify-center items-center bg-cover" style={{ backgroundImage: `url(${BgLogin})` }}>
            <div className="custom-button-shadow custom-button-shadow-two flex flex-col bg-no-repeat bg-contain bg-cover bg-center justify-center h-screen lg:h-auto items-center w-full lg:w-4/12 p-10 lg:p-10 rounded-lg shadow z-50" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <img src={CryptotradexLogo} className="w-2/5 mb-1 mx-auto" alt={"Cryptotradex"} />
                <form className="mt-8 space-y-3 lg:p-5 z-40 w-full" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="email" className="block mb-2 font-medium text-gray-900 text-white">{t("_LOGIN._EMAIL")}</label>
                        <input type="email" name="email" id="email" className="text-white sm:text-sm" placeholder="name@company.com" required {...register('email')} />
                    </div>
                    <div>
                        <label htmlFor="password" className="block mb-2  font-medium text-gray-900 text-white">{t("_LOGIN._PASSWORD")}</label>
                        <div className="relative">
                            <input type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder="••••••••" className="text-white sm:text-sm" required {...register('password')} />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                                        <path strokeLinecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>

                                )}
                            </div>
                        </div>
                    </div>
                    <CustomButton text={t("_LOGIN._LOGIN")} isSubmit={true} disabled={loading} align={"justify-center"} />
                    <div className="flex justify-center">
                        <Link to="/recover-password" className="mx-auto mt-2 hover:underline text-center text-white underline font2">{t("_LOGIN._FORGOTPASSWORD")}</Link>
                    </div>
                    {/* <div className="text-sm font-medium text-gray-400 text-center">
                            <Link to="/signup" className="hover:underline text-primary-500">{t("_LOGIN._NOACCOUNT")} <span className='text-white'>{t("_LOGIN._SIGNUP")}</span></Link>
                        </div> */}
                    {/* <div className="text-sm font-medium text-gray-400 text-center">
                            <Link to="/recover-password" className="hover:underline text-primary-500"> <span className='text-white'>{t("_LOGIN._FORGOTPASSWORD")}</span></Link>
                        </div> */}
                </form>
            </div>
        </div>
    );
}