export const de = {
  _LOGIN: {
    _EMAIL: "E-Mail",
    _PASSWORD: "Passwort",
    _REMEMBER: "Erinnere dich an mich",
    _LOGIN: "ANMELDEN",
    _SIGNUP: "Registrieren",
    _FORGOTPASSWORD: "Passwort vergessen?",
    _NOACCOUNT: "Noch kein Konto?",
    _ERROR_EMAIL: "Anscheinend bist du nicht registriert! Versuche es erneut.",
    _ERROR_PASSWORD: "Falsches Passwort",
    _SUCCESS: "Anmeldung erfolgreich",
  },
  _REGISTER: {
    _REGISTER: "Konto registrieren",
    _NAME: "Benutzername",
    _EMAIL: "Deine E-Mail",
    _PASSWORD: "Dein Passwort",
    _CONFIRMPASSWORD: "Passwort bestätigen",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (optional)",
    _HAVEACCOUNT: "Ich habe ein Konto",
    _ERROR_REFERAL: "Falscher Empfehlungscode",
    _ERROR_EMAIL: "Diese E-Mail existiert bereits",
    _SUCCESS: "Registrierung erfolgreich",
  },
  _SIDEBAR: {
    _PAGES: "Seiten",
    _OPEN: "Seitenleiste öffnen",
    _DASHBOARD: "Startseite",
    _NETWORK: "Netzwerk",
    _FINANCE: "Geldbörse",
    _PACKAGES: "Pläne",
    _SIGNOUT: "Abmelden",
    _LANGUAGE: "Sprache",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Kontostand",
    _TITLE2: "Einzahlung",
    _TITLE3: "Gesamteinnahmen",
    _TITLE4: "Unilevel Einnahmen",
    _TITLE5: "Rangbonus",
    _TITLE6: "Plan",
    _TOTAL: "Gesamt",
    _TABLE3: "Paketliste",
    _START: "Start",
    _FINISH: "Ende",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktiv",
    _STATUS_INACTIVE: "Inaktiv",
    _CURRENT: "Aktuell",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Erfolgreich kopiert",
    _NOTIFICATION1: "Neue Einzahlung",
    _NOTIFICATION2: "Cryptotradex Gewinn",
    _NOTIFICATION3: "Unilevel Gewinn",
    _NOTIFICATION4: "Unendliche Reichweite",
    _NOTIFICATION5: "Paket kaufen",
    _PREV: "Vorherige",
    _NEXT: "Nächste",
    _TABLE4_A: "Operation",
    _TABLE4_B: "Gewinn",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Datum",
    _SHARED: "Teile deinen Empfehlungslink",
  },
  _FINANCE: {
    _DEPOSIT: "Einzahlung",
    _QR: "Scanne den folgenden QR-Code oder kopiere die Adresse",
    _NETWORK: "Netzwerk",
    _INSTRUCTIONS: "Anweisungen zum Senden von TRON (TRX)",
    _STEP1: "Stelle sicher, dass das ausgewählte Netzwerk TRON (TRC20) ist.",
    _STEP2: "Sende den Betrag von TRON (TRX) an die folgende Wallet-Adresse.",
    _COPY: "Kopieren",
    _DISCLAIMER:
      "Haftungsausschluss: Wir sind nicht verantwortlich für Transaktionen außerhalb des TRON (TRC20) Netzwerks oder für das Senden anderer Kryptowährungen als TRON (TRX). Jeder Verlust von Geldern aufgrund dieser Fehler liegt in der alleinigen Verantwortung des Absenders.",
    _TABLE1: "Einzahlungshistorie",
    _TABLE1_A: "Bestellung",
    _TABLE1_B: "Betrag",
    _TABLE1_C: "Datum",
    _TABLE1_D: "Status",
    _SUCCESS: "Das Paket wurde erfolgreich hinzugefügt",
    _COPYMESSAGE: "Wallet-Adresse erfolgreich kopiert",
  },
  _PACKAGES: {
    _ATTENTION:
      "Achtung: Du hast ein Firmenkonto. Beim Kauf eines Pakets wird das Konto und alle seine Vorteile zurückgesetzt.",
    _PACKAGES: "Wähle den besten Plan für dich",
    _SUBTITLE: "Du hast 8 Optionen",
    _BALANCE: "Einzahlungssaldo",
    _PACKAGE: "Paket",
    _PACKAGE_1: "Du wirst ab Level verdienen",
    _PACKAGE_2: "Verdiene bis zu 3X",
    _PACKAGE_3: "Täglicher ROI",
    _BUTTON1: "Ausgefüllt",
    _BUTTON2: "Kaufen",
    _CALCULATE: "Plan berechnen",
    _RECHARGE: "Du hast nicht genug Geld, lade dein Wallet auf",
    _PACKAGEMESSAGE: "Du musst ein höheres Paket kaufen",
    _SUCCESS: "Das Paket wurde erfolgreich hinzugefügt",
  },
  _NETWORK: {
    _TODAY: "Heute",
    _AVAILABLE: "Verfügbar",
    _WITHDRAW: "Gesamtabhebungen",
    _SEARCHLABEL: "E-Mail-Suche",
    _SEARCH: "Suche",
    _NETWORK: "Netzwerk",
    _DIRECTS: "Direkte",
    _SUCCESS: "Erfolgreiche Suche",
    _ERROR: "Nicht Teil deines Binärbaums.",
    _LEVEL: "Level",
    _USERS: "Benutzer",
    _AMOUNT: "Betrag",
    _USERNAME: "Benutzername",
    _EMAIL: "E-Mail",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Paket",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Dein Name",
    _EMAIL: "Deine E-Mail",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Aktuelles Passwort",
    _PASSWORD: "Neues Passwort",
    _CONFIRMPASSWORD: "Neues Passwort bestätigen",
    _WALLET: "Auszahlungsadresse",
    _ACTION: "Bearbeiten",
    _TIPS: "Tipps",
    _TIPS_1:
      "Der Name muss lesbar sein und darf 20 Zeichen nicht überschreiten.",
    _TIPS_2:
      "Das Passwort muss Zahlen, Buchstaben und Symbole enthalten; je komplexer, desto besser.",
    _TIPS_3:
      "Die E-Mail-Adresse kann nicht geändert werden. Wenn du eine Änderung vornehmen musst, kontaktiere bitte den Support.",
    _TIPS_4:
      "Die Auszahlungsadresse kann nur mit einer zuvor aktivierten Zwei-Faktor-Authentifizierung geändert werden.",
    _ERROR_PASSWORD: "Vorheriges Passwort falsch",
    _SUCCESS: "Profil erfolgreich aktualisiert",
    _MIN: "Mindestabhebung",
    _UPDATE: "Profil aktualisieren",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Passwort vergessen?",
    _EMAIL: "Deine E-Mail",
    _BUTTON: "E-Mail senden",
    _MESSAGE: "Dein Passwort wurde an deine E-Mail gesendet",
    _ERROR_EMAIL: "Diese E-Mail ist nicht registriert",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Aktueller Rang",
    _NEXT: "Nächster Rang",
    _SILVER: "Silber",
    _GOLD: "Gold",
    _RUBY: "Rubin",
    _EMERALD: "Smaragd",
    _DIAMOND: "Diamant",
    _DIAMONDB: "Blauer Diamant",
    _DIAMONDBB: "Schwarzer Diamant",
    _NONE: "Keiner",
  },
  _AUTH: {
    _TITLEDEFAULT: "Auszahlungswallet ändern",
    _TITLE: "Schritt 1",
    _TITLE2: "Schritt 2",
    _TITLE3: "Schritt 3",
    _STEP1:
      "Lade den Authenticator auf dein Gerät herunter und installiere ihn",
    _STEP2: "Scanne den Code, um ihn zu speichern",
    _FORMEMAIL: "E-Mail-Bestätigung",
    _FORMHELPEMAIL:
      "Gib den 6-stelligen Bestätigungscode ein, der gesendet wurde an",
    _FORMHELPAUTH:
      "Gib den 6-stelligen Code ein, der von der Authenticator-App generiert wurde",
    _FORM1: "Pin durch Authenticator",
    _FORM2: "Auszahlungsadresse",
    _FORMMIN: "Die Auszahlungsadresse muss 34 Zeichen lang sein",
    _FORMCODESEND: "Code gesendet",
    _VERIFF: "Überprüfen",
    _ACTION: "Überprüfen und speichern",
    _ERROR: "Fehler, versuche es erneut.",
    _SUCCESS: "Änderung erfolgreich",
    _RETRYOTP: "Fehler beim Senden des Codes, versuche es erneut.",
    _SENDOTP: "Code erfolgreich gesendet",
    _SUCCESSOTP: "OTP erfolgreich verifiziert",
    _SUCCESSAUTH2F: "Zwei-Faktor-Authentifizierung erfolgreich verifiziert",
    _ERRORCODE: "Ungültiger Code, versuche es erneut",
  },
  _COUNTDOWN: {
    _TITLE: "Nächster Schnitt:",
    _DAYS: "Tage",
    _HOURS: "Stunden",
    _MINUTES: "Minuten",
    _SECONDS: "Sekunden",
  },
  _WITHDRAWAL: {
    _TITLE: "Abhebungstransaktionen",
  },
};
