import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";

export default function Language() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const { language, showLanguage } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('overflow-y-hidden');
        return () => {
            document.body.classList.remove('overflow-y-hidden');
        };
    }, []);

    const changeLng = (language) => {
        localStorage.setItem("lng", language);
        i18n.changeLanguage(language);
    };

    const closeLanguage = () => {
        showLanguage(false);
    }

    return (
        <>
            <div className="w-screen absolute h-full" style={{ zIndex: "100", backgroundColor: "#00000091" }}>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  custom-button-shadow-two rounded p-10 bg-zinc-800 w-11/12 lg:w-3/5">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeLanguage()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-lime-300 text-end">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <div className="flex flex-col w-full justify-center lg:justify-left items-center lg:gap-4">
                        <div className="text-lime-300">{t("_SIDEBAR._LANGUAGE")}</div>
                        <div className="flex flex-col lg:flex-row text-white w-full">
                            <div onClick={() => changeLng("Spanish")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇪🇸 Español</div>
                            <div onClick={() => changeLng("Italian")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇮🇹 Italiano</div>
                            <div onClick={() => changeLng("Portuguese")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇵🇹 Português</div>
                            <div onClick={() => changeLng("Hindi")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇮🇳 हिंदी</div>
                            <div onClick={() => changeLng("Rumano")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇷🇴 Română</div>
                        </div>
                        <div className="flex flex-col lg:flex-row text-white w-full">
                            <div onClick={() => changeLng("English")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇬🇧 English</div>
                            <div onClick={() => changeLng("Arabe")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇦🇪 العربية</div>
                            <div onClick={() => changeLng("French")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇫🇷 Français</div>
                            <div onClick={() => changeLng("Koreano")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇰🇷 한국어</div>
                            <div onClick={() => changeLng("Tailandes")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇹🇭 ไทย</div>
                        </div>
                        <div className="flex flex-col lg:flex-row text-white w-full">
                            <div onClick={() => changeLng("Vietnamese")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇻🇳 Việt Nam</div>
                            <div onClick={() => changeLng("Filipino")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇵🇭 Tagalog</div>
                            <div onClick={() => changeLng("Chinese")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇨🇳 中文</div>
                            <div onClick={() => changeLng("Russian")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇷🇺 Русский</div>
                            <div onClick={() => changeLng("Aleman")} className={"hover:text-lime-300 cursor-pointer w-full lg:w-1/4 "}>🇩🇪 Deutsch</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
