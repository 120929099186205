import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import CardBalance from "../assets/svg/cards/balance.svg"
import CardDeposit from "../assets/svg/cards/deposit.svg"
import CardBonus from "../assets/svg/cards/bonus.svg"
import CardEarning from "../assets/svg/cards/earnings.svg"
import CardUnilevel from "../assets/svg/cards/unilevel.svg"
import CardProfit from "../assets/svg/cards/profit.svg"
import CardInvite from "../assets/svg/cards/invite.svg"
import AppContext from "../context/AppContext";
import toast from "react-hot-toast";
import Countdown from "../components/Countdown";
import GlobalVolumen from "../components/GlobalVolumen";
import QRCode from "react-qr-code";
import PackageCard from "../components/PackageCard";

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, ArcElement, Legend } from 'chart.js';
import { format } from "date-fns";

ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

export default function Home() {
  const { t } = useTranslation();
  const { loading, updateLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [packagesOperation, setPackagesOperation] = useState([]);
  const [emptyPackage, setEmptyPackage] = useState(false);
  const [hoursServer, setHoursServer] = useState();
  const [balance, setBalance] = useState(0);
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [earningsTotal, setEarningsTotal] = useState(0);
  useEffect(() => {
    getUsersData();
    getNotificationsPackages();
  }, []);

  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data;
        setUserData(data);
        if (data && data.package_value_maximun && data.package_value_current) {
          const max = data.package_value_maximun;
          const current = data.package_value_current;
          const calculatedPercentage = (current / max) * 100;
          setPercentage(calculatedPercentage);
        }
        setBalance(parseFloat(data?.balance_available) + parseFloat(data?.balance_bonus) + parseFloat(data?.balance_referral));
        setBalanceTotal(parseFloat(data?.total_available) + parseFloat(data?.total_bonus) + parseFloat(data?.total_referral))
        setEarningsTotal(parseFloat(data?.total_available) + parseFloat(data?.total_bonus) + parseFloat(data?.total_referral));
        setHoursServer(response?.hour);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const getNotificationsPackages = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/notification?limit=50`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data?.items;
        console.log(response);
        if (data) {
          // Filtrar por acción
          const filteredData = data.filter(item => item.action === "2" || item.action === "3" || item.action === "4");

          if (filteredData.length === 0) {
            setEmptyPackage(true);
          } else {
            // Agrupar por action y fecha (sin tiempo), sumando los valores
            const groupedData = filteredData.reduce((acc, item) => {
              const { action, created, value } = item;
              const dateOnly = created.slice(0, 10); // Obtener solo la fecha 'YYYY-MM-DD'
              const groupKey = `${action}-${dateOnly}`; // Clave única para agrupar por acción y fecha

              if (!acc[groupKey]) {
                acc[groupKey] = { action, created: dateOnly, totalValue: 0 };
              }
              acc[groupKey].totalValue += parseFloat(value); // Sumar los valores
              return acc;
            }, {});

            // Convertir el objeto agrupado de nuevo a un array
            const resultArray = Object.values(groupedData);
            setPackagesOperation(resultArray); // Establecer los datos agrupados y sumados
          }
        } else {
          console.log('vacio');
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }




  const goToPackages = () => {
    navigate("/packages");
  }


  const nameNotification = (name) => {
    if (name === "3") {
      return t("_DASHBOARD._NOTIFICATION3");
    } else if (name === "2") {
      return t("_DASHBOARD._NOTIFICATION2");
    } else if (name === "1") {
      return t("_DASHBOARD._NOTIFICATION1");
    } else if (name === "5") {
      return t("_DASHBOARD._NOTIFICATION5");
    } else if (name === "4") {
      return t("_DASHBOARD._NOTIFICATION4");
    }
  }


  const copyShared = (code) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND}/signup?invite=${code}`
    );
    toast.success(t("_DASHBOARD._COPYMESSAGE"));
  };

  const oraculo = (props) => {
    if (window.localStorage.getItem("tron")) {
      let value = props / window.localStorage.getItem("tron");
      let fomart = Number.parseFloat(value).toFixed(3);
      let valueFormat = formatNumber(fomart);
      return valueFormat;
    }
  };

  const formatNumber = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split('.');

    if (decimalPart && decimalPart.length > 2) {
      return `${integerPart}.${decimalPart.slice(0, 2)}`;
    }

    return valueString;
  };


  return (
    <div className="container-full">
      {/** Buttons speed */}
      <div className="flex flex-col lg:flex-row gap-8 h-auto">
        <div className="flex flex-col w-full lg:w-[78%] gap-4 lg:min-h-full lg:h-auto justify-between">
          <div className="flex flex-col lg:flex-row gap-8 mb-4">
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardBalance})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0 ">{formatNumber(balance || 0)} USD </p>
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0"> {formatNumber(balanceTotal || 0)} {t("_DASHBOARD._TOTAL")} </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(balance)} TRX </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(balanceTotal)} {t("_DASHBOARD._TOTAL")} </p>
                </div>
              </div>
            </div>
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardDeposit})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE2")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block  transition-opacity duration-300 opacity-100 ">{formatNumber(userData?.balance_deposit || 0)} TRX </p>
                  <p className="block  transition-opacity duration-300 opacity-100 "> {formatNumber(userData?.total_deposit || 0)} {t("_DASHBOARD._TOTAL")}  </p>
                </div>
              </div>
            </div>
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardEarning})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE3")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block transition-opacity duration-300 opacity-100 ">{formatNumber(earningsTotal || 0)} <small> USD </small></p>
                  <p className="block transition-opacity duration-300 opacity-100 "> {oraculo(earningsTotal)} <small> TRX </small> </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardUnilevel})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE4")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.balance_referral || 0)} USD </p>
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.total_referral || 0)} {t("_DASHBOARD._TOTAL")} </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.balance_referral)}  TRX </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.total_referral)}{t("_DASHBOARD._TOTAL")} </p>
                </div>
              </div>
            </div>
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardBonus})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE5")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.balance_bonus || 0)} USD </p>
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.total_bonus || 0)} {t("_DASHBOARD._TOTAL")} </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.balance_bonus)}  TRX </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.total_bonus)}  {t("_DASHBOARD._TOTAL")} </p>
                </div>
              </div>
            </div>
            <PackageCard userData={userData} />
          </div>
        </div>
        <GlobalVolumen userData={userData} />
      </div>

      {/** Mi Package Barra */}
      <div className="flex flex-col lg:flex-row gap-4 h-auto my-4 lg:mt-16">
        <div className="w-full border-lime-600 border-2 bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${CardProfit})` }}>
          <div className="mx-5 flex flex-row justify-end my-5">
            <p>{t("_DASHBOARD._FINISH")}: {userData?.package_value_maximun || 0} USD</p>
          </div>
          <div className="relative h-8 rounded-2xl overflow-hidden bg-gray-300 my-5 mx-5">
            <div className="absolute top-0 h-8 rounded-2xl shim-green"
              style={{ width: `${percentage}%` }}>
            </div>
          </div>
          <div className="mx-5 flex flex-row justify-between my-5">
            <div className="flex flex-row justify-center items-center">
              <p className="hidden lg:block"> {t("_DASHBOARD._STATUS")}: {userData?.active === "1" ? t("_DASHBOARD._STATUS_ACTIVE") : t("_DASHBOARD._STATUS_INACTIVE")}</p>
              {userData?.package !== "8" && (
                <div className="mx-2 flex flex-row justify-between hidden lg:block">
                  <button className="text-md w-auto border-2 p-1 cursor-pointer rounded-lg" onClick={() => goToPackages()}>{t("_DASHBOARD._UPGRADE")} </button>
                </div>
              )}
            </div>
            <p> {t("_DASHBOARD._CURRENT")}: {userData?.package_value_current ? userData?.package_value_current : "0"} USD - {percentage ? percentage.toFixed(2) : "0"}%</p>
          </div>
        </div>
      </div>
      {userData?.active === "1" && (
        <div className="flex flex-col lg:flex-row gap-4 h-auto my-4">
          <div className="flex flex-col lg:flex-row justify-between w-full border-lime-600 border-2 bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${CardInvite})` }} onClick={() => copyShared(userData?.referred_my)}>
            <div className="mx-5 w-full lg:w-1/2 gap-4 flex flex-col justify-center">
              <p>{t("_DASHBOARD._SHARED")}</p>
              <p className="inline lg:hidden break-words  text-xs md:text-sm text-white my-6 ">
                <span className="block lg:hidden cursor-pointer bg-gray-800 text-white font-medium p-4 rounded my-4 border-lime-600 border-2 w-full lg:w-auto">
                  {userData?.referred_my}
                </span>
              </p>
              <p className="hidden lg:block  break-words  text-xs md:text-sm text-white my-6 ">
                <span className="cursor-pointer bg-gray-800 text-white font-medium p-4 rounded my-4 border-lime-600 border-2 w-full lg:w-auto">
                  {process.env.REACT_APP_FRONTEND}/signup?invite={userData?.referred_my}
                </span>
              </p>
            </div>
            <div className="lg:mx-5 w-full lg:w-1/2 flex lg:justify-end ">
              <QRCode
                size={128}
                bgColor={"#65A30D"}
                value={process.env.REACT_APP_FRONTEND + "/signup?invite=" + userData?.referred_my}
                className={"w-full lg:w-[20%] p-4 rounded-lg bg-lime-600 text-white"}
                viewBox={`0 0 128 128`}
              />
            </div>
          </div>
        </div>
      )}
      {/** Cuenta regresiva  + Disponibilidad
      {userData?.active && (
        <div className="w-full border-lime-600 border-2  bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4" >
          <div className="w-full min-h-34 rounded-lg flex flex-col p-4 gap-2 bg-cover bg-center relative"  >
            <Countdown targetDate={userData?.activeDate} dateServer={hoursServer} />
          </div>
        </div >
      )}
        */}
      {/** Mi Profits Package List */}
      {userData?.active && (
        <div className="gap-4 h-auto ">
          <div className="w-full rounded-lg shadow-md h-auto text-white ">
            <div className="mx-5 flex flex-row justify-end my-5 items-center">
              <Link to="/withdrawal">
                <button className="text-md border-2 py-2 px-4 cursor-pointer rounded-lg" > {t("_WITHDRAWAL._TITLE")}</button>
              </Link>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full">
              <div className="relative overflow-x-auto w-full">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                  <thead className="text-md text-white border-b-2">
                    <tr>
                      <th className="px-6 py-4 ">
                        {t("_DASHBOARD._TABLE4_A")}
                      </th>
                      <th className="px-6 py-4">
                        {t("_DASHBOARD._TABLE4_B")}
                      </th>
                      {/*   <th className="px-6 py-4 ">
                        {t("_DASHBOARD._TABLE4_C")}
                      </th>
                 
                      <th className="px-6 py-4 ">
                        Hash
                      </th>
                      */}
                      <th className="px-6 py-4 ">
                        {t("_DASHBOARD._TABLE4_D")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packagesOperation.map((item, index) => (
                      <tr className="border-b-2" key={index}>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                          {nameNotification(item?.action)}
                        </th>
                        <td className="px-6 py-4 text-white">
                          {item?.totalValue ? formatNumber(item.totalValue) : 0} USD
                        </td>
                        {/*   <td className="px-6 py-4 text-white">
                          {item?.message ? item.message + "%" : "-"}
                        </td>
                     
                        <td className="px-6 py-4 text-white">
                          {item?.link ? item.link : "-"}
                        </td>
                          */}
                        <td className="px-6 py-4 text-white">
                          {item?.created ? item.created : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
}
